<template>
  <div class="player-setup" v-if="parseInt($route.params.playerN)>0">
    <div class="row">
      <div class="col s3">Название</div>
      <input class="col s9" type="text" v-model="hub.players[$route.params.playerN-1].name"
             placeholder="Напр., Капитаны">
    </div>
    <div class="row flex flex-wrap">
      <div class="col s12 m8 l6 flex flex-direction-column justify-center">
        <div class="field flex flex-direction-column justify-center">
          <div class="row">
            <div class="cell cellH"></div>
            <div class="cell cellH" v-for="l in letters" :key="'cell'+0+'-'+l">{{ l }}</div>
          </div>
          <div class="row" v-for="(row,y) in hub.players[$route.params.playerN-1].field" :key="'row'+y">
            <div class="cell cellH">{{ y + 1 }}</div>
            <div class="cell" :class="{ship:hub.players[$route.params.playerN-1].field[y][x]==='ship'}"
                 v-for="(exist,x) in row" :key="'cell'+x+'-'+y" @click="triggerShip(x,y)"></div>
          </div>
        </div>
      </div>
      <div class="col s12 m4 l4 flex flex-direction-column">
        <div class="input-field">
          <input id="player_field_code" type="text" v-model="code" @input="load">
          <label for="player_field_code" class="active">Вставьте код поля для загрузки</label>
        </div>
<!--        <input class="" type="text" v-model="code" @input="load">-->
<!--        <a class="waves-effect waves-light btn  blue lighten-1" @click="load">Загрузить</a>-->
      </div>
    </div>
    <div class="row" style="margin:20px;">
      <div class="col s3"></div>
      <a class="col s6 waves-effect waves-light btn  blue lighten-1" @click="save">Сохранить</a>
      <div class="col s3"></div>
    </div>
    <div class="row" style="margin:20px;">
      <div class="col s3"></div>
      <router-link :to="{name:'gamePreset'}" class="col s6 waves-effect waves-light btn cyan">Главная</router-link>
      <div class="col s3"></div>
    </div>
  </div>

</template>

<script>
import eventHub from "@/eventHub";

export default {
  name: "playerSetup",
  computed: {
//    hub (){return eventHub},
  },
  data() {
    return {
      dim: 10,//hub.players[this.$route.params.playerN-1].field.length,
      hub: eventHub,
      letters: ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ж', 'З', 'И', 'К',],
      code:'',
    };
  },
  methods: {
    triggerShip(x, y) {
      if (this.hub.players[this.$route.params.playerN - 1].field[y][x] === 'ship') {
        eventHub.$set(this.hub.players[this.$route.params.playerN - 1].field[y], x, '')
      } else
        eventHub.$set(this.hub.players[this.$route.params.playerN - 1].field[y], x, 'ship');
      this.code=this.getCode();
    },
    test(x, y, skip) {
      if (x < 0 || y < 0 || x >= this.dim || y >= this.dim) return 0;
      if (!skip.has(x + this.dim * y)) {
        if (this.hub.players[this.$route.params.playerN - 1].field[y][x])
          return this.hub.players[this.$route.params.playerN - 1].field[y][x];
      }
      return 0;
    },
    save() {
      this.hub.players[this.$route.params.playerN - 1].ships = Array.from(Array(this.hub.shipMaxSize), () => new Array());
      let skip = new Set();
      let queue = new Array();
      for (let y = 0; y < this.dim; y++) {
        for (let x = 0; x < this.dim; x++) {
          if (this.hub.players[this.$route.params.playerN - 1].field[y][x] !== 'ship') {
            skip.add(x + this.dim * y);
          }
          // else{
          //   queue.push({x,y});
          // }
        }
      }
      for (let y = 0; y < this.dim; y++) {
        for (let x = 0; x < this.dim; x++) {
          if (this.hub.players[this.$route.params.playerN - 1].field[y][x] === 'ship' && !skip.has(x + this.dim * y)) {
            queue.push({x, y});
            skip.add(x + this.dim * y);
          } else continue;
          let ship = [];
          while (queue.length) {
            let cx = queue[0].x;
            let cy = queue[0].y;
            // while (skip.has(x + this.dim * y)) {
            //   queue.shift();
            //   if (queue.length) {
            //     x = queue[0].x;
            //     y = queue[0].y;
            //   } else break;
            // }
            // if (!queue.length) break;
            ship.push({x: cx, y: cy})
            let shi = [[0, 1], [0, -1], [1, 0], [-1, 0]];
            for (let s = 0; s < 4; s++)
              if (this.test(cx + shi[s][0], cy + shi[s][1], skip) === 'ship') {
                queue.push({x: cx + shi[s][0], y: cy + shi[s][1]});
                skip.add((cx + shi[s][0]) + this.dim * (cy + shi[s][1]))
              }
            queue.shift();
          }
          if (ship.length > 0 && ship.length <= this.hub.shipMaxSize)
            this.hub.players[this.$route.params.playerN - 1].ships[ship.length - 1].push(ship);
        }
      }
      for (let ss=0;ss<this.hub.shipMaxSize;ss++)
        if (this.hub.players[this.$route.params.playerN - 1].ships[ss].length>0) console.log((ss+1)+':'+this.hub.players[this.$route.params.playerN - 1].ships[ss].length);
      this.hub.$emit('updateGameCode');
    },
    getCode() {
      let c = '';
      for (let y = 0; y < this.dim; y++) {
        for (let x = 0; x < this.dim; x++) {
          if (this.hub.players[this.$route.params.playerN - 1].field[y][x] === 'ship')
            c += '1';
          else c += '0';
        }
      }
      return parseInt(c.substring(0,25), 3).toString(26).toUpperCase()+'-'+parseInt(c.substring(25,50), 3).toString(26).toUpperCase()+'-'+parseInt(c.substring(50,75), 3).toString(26).toUpperCase()+'-'+parseInt(c.substring(75), 3).toString(26).toUpperCase();
    },
    load(){
      let cell=null;
      let parts=this.code.split('-');
      if (parts && parts.length===4){
        let c=(parseInt(parts[0],26).toString(3).padStart(25,'0')+parseInt(parts[1],26).toString(3).padStart(25,'0')+parseInt(parts[2],26).toString(3).padStart(25,'0')+parseInt(parts[3],26).toString(3).padStart(25,'0')).split("");
        console.log(c);
        for (let y = 0; y < this.dim; y++) {
          for (let x = 0; x < this.dim; x++) {
            cell=c.shift();
            if (cell === '1')
              this.hub.$set(this.hub.players[this.$route.params.playerN - 1].field[y],x,'ship');
            else
              this.hub.$set(this.hub.players[this.$route.params.playerN - 1].field[y],x,null);
          }
        }
      }
    },
  },
  mounted() {
     this.code='';
  },
  watch:{
    $route (){
      this.code=this.getCode();
    }
  },
}
</script>

<style scoped lang="scss">
.row {
  display: flex;
  margin-bottom: 0;
}
.field{
}
.btn {
  margin-bottom: 5px;
}

</style>